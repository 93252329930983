import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Depoimentos',
  components: { VueSlickCarousel },
  data: () => ({
    depoimentos: [],
    carrosselCards: {
      // lazyLoad: 'ondemand',
      dots: true,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 4,
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1368,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 599,
          settings: {
            arrows: false,
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        }
      ]
    }
  }),
  mounted () {
    this.depoimentos = [

      {
        img: 'icones/quote',
        nome: 'Pedro Camilo',
        mensagem:
          'Empresa com muita credibilidade, e vendedores muito atenciosos, que fizeram a diferença na hora de finalizar a compra. Muito obrigado a Viviane e João Bahia pelo atendimento totalmente diferenciado e pela paciência.'
      },
      {
        img: 'icones/quote',
        nome: 'Vagner Nascimento',
        mensagem:
          'Gostei muito do veículo, e também o atendimento do João Bahia, que me atendeu super bem , deu uma atenção do início ao fim da negociação , e para finalizar o excelente atendimento também da vendedora Viviane👏🏻👏🏻👏🏻'
      },
      {
        img: 'icones/quote',
        nome: 'Wallace Filipini',
        mensagem:
          'O vendedor Jefferson, extremamente atencioso e preocupado em prestar um bom serviço. Estou gostando bastante do carro e do processo de toda burocracia. O Carro deu uns probleminhas, mas o atendimento foi muito bom e o problema resolvido, as pessoas realmente se preocupam com o bem estar do cliente.'
      },
      {
        img: 'icones/quote',
        nome: 'Camila Vieira',
        mensagem:
          'A experiência foi ótima! A Viviane e a Bianca foram ágeis, gentis e sinceras desde o começo da negociação! Fizemos um ótimo negócio e super recomendo.'
      }
    ]
  }
}
